import Loadable from 'react-loadable'
// import { PageLoading } from '@/components'

const PageLoadable = Loadable({
  loader: () => import('.'),
  // loading: PageLoading,
  loading: () => null,
})


export default PageLoadable
