import React from 'react';
import styled from 'styled-components';
import { NavLink as RawNavLink } from 'react-router-dom';
import { useIntl } from 'react-intl'
import { toUrl } from '../LanguageProvider';

const Wrap = styled.footer`
  padding: 20px;
  border-top: 1px solid #ddd;
`

const SubWrap = styled.div`
  box-sizing: border-box;
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Brand = styled.div`
  letter-spacing: 0.5px;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 2px;
`

const Copyright = styled.div`
  font-size: 13px;
  /* color: #888; */
`

const NavLink = styled(RawNavLink)`
  color: #333;
  margin: 10px 6px;
`

const Footer = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrap>
      <SubWrap>
        <Brand>
          visify
        </Brand>
      </SubWrap>
      <SubWrap>
        <NavLink to={ toUrl('/terms') }>
          { formatMessage({ id: 'terms' }) }
        </NavLink>
        <NavLink to={ toUrl('/policy') }>
          { formatMessage({ id: 'policy' }) }
        </NavLink>
        <a style={{ color: '#333', margin: '10px 6px' }} href="mailto:weekdays.tech@gmail.com">
          { formatMessage({ id: 'contact' }) }
        </a>
      </SubWrap>
      <SubWrap>
        <Copyright>
          © Copyright { new Date().getFullYear() }. All rights reserved.
        </Copyright>
      </SubWrap>
      {/* <Cell>
        { formatMessage({ id: 'social-media' }) }
      </Cell> */}
    </Wrap>
  );
};


export default Footer;
