import React from 'react'
import get from 'lodash/get'
import { IntlProvider  } from 'react-intl'
/* eslint-disable-next-line */
import { withRouter, RouteComponentProps, match } from 'react-router';

import en from '../../i18n/en.json'
import zh from '../../i18n/zh.json'

const messages = {
  en,
  zh,
}

const LANGUAGES = [
  'zh',
  'en',
]

const DEFAULT_LANG = LANGUAGES[0]


export function getCurrentLang(match) {
  if (match.params && LANGUAGES.indexOf(match.params.lang) !== -1) {
    return match.params.lang != null
      ? match.params.lang
      : DEFAULT_LANG
  }
  return DEFAULT_LANG
}

export function getCurrentLangByLocation() {
  if (window.location.pathname.slice(1, 3) === 'en') {
    return 'en'
  }
  return 'zh'
}

export function fromUrl(url) {
  if (getCurrentLangByLocation() === 'en') {
    return url.replace('/en', '')
  }
  return url
}

export function toUrl(url) {
  const lang = getCurrentLangByLocation()
  if (lang === 'en') {
    return `/en${url}`
  }
  return url
}

let currentLocale = DEFAULT_LANG

const LanguageProvider = ({
  children,
  match,
}) => {
  const locale = getCurrentLangByLocation();
  return <IntlProvider
    locale={ locale }
    messages={messages[locale]}
  >
    { children }
  </IntlProvider>
}

export const dangerouslyFormatMessage = (key) => {
  return get(messages[currentLocale], key, key)
}

export default withRouter(LanguageProvider)
