// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

import React from 'react';
import { render, hydrate } from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from 'react-ga'

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

