// This is for root level router
// you can create any nested in the pages of if needed
import React, { Component } from 'react'
// import styled from 'styled-components'

import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom'
import LanguageProvider from '../containers/LanguageProvider'
import Header from '../containers/Header'
import Footer from '../containers/Footer'

import Home from '../pages/Home/loadable'
import Terms from '../pages/Terms/loadable'
import TermsEn from '../pages/TermsEn/loadable'
import Policy from '../pages/Policy/loadable'
import PolicyEn from '../pages/PolicyEn/loadable'
import FAQ from '../pages/FAQ/loadable'

import styled from 'styled-components';
import { Sizes } from '../style/constant'

const PlaceHolder = styled.div`
  height: 62px;
  width: 100%;
  @media (max-width: ${Sizes.screen.mobile}px) {
    height: 40px;
  }
`

const Main = styled.main`
  min-height: 100vh;
`

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

const ScrollToTopComp = withRouter(ScrollToTop)

const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTopComp>
        <LanguageProvider>
          <>
            <Header />
            <PlaceHolder />
            
            <Main>
              {/* <ControlPanel /> */}
              <Switch>
                <Route
                  path="/"
                  render={ () => <Home /> }
                  exact
                />
                <Route
                  path="/terms"
                  render={ () => <Terms /> }
                  exact
                />
                <Route
                  path="/policy"
                  render={ () => <Policy /> }
                  exact
                />
                {/* <Route
                  path="/faq"
                  render={ () => <FAQ /> }
                  exact
                /> */}

                <Route
                  path="/en"
                  render={ () => <Home /> }
                  exact
                />
                <Route
                  path="/en/terms"
                  render={ () => <TermsEn /> }
                  exact
                />
                <Route
                  path="/en/policy"
                  render={ () => <PolicyEn /> }
                  exact
                />
              </Switch>
            </Main>
            <Footer />
          </>
        </LanguageProvider>
      </ScrollToTopComp>
    </BrowserRouter>
  )
}

export default Router
