import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl'
import { NavLink as RawNavLink, useHistory, Link } from 'react-router-dom'
import { Layout } from '../../constant';
import { Sizes } from '../../style/constant'

const Wrap = styled.header`
  height: 62px;
  /* background-color: rgba(255,255,255,.95); */
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
  @media (max-width: ${Sizes.screen.mobile}px) {
    height: 40px;
  }
`

const NavLink = styled.a`
  padding: 0 10px;
  color: #333;
  text-decoration: none;
`

const Inner = styled.div`
  max-width: ${Layout.containerWidth}px;
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Brand = styled(RawNavLink)`
  color: #000;
  text-decoration: none;
  letter-spacing: 0.5px;
  font-size: 20px;
  font-weight: bold;
  /* color: #fff; */
`

const Right = styled.div`
  margin-left: 20px;
`

const Left = styled.div`
  margin-right: 20px;
`

const Header = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrap>
      <Inner>
        <Right>
          <Brand to="/">
            visify
          </Brand>
        </Right>
        <Left>
          <NavLink href="https://visify-app.weekdays.tech/login">
            { formatMessage({ id: 'login' }) }
          </NavLink>
          <NavLink href="https://visify-app.weekdays.tech/signup">
            { formatMessage({ id: 'signup' }) }
          </NavLink>
        </Left>
      </Inner>
    </Wrap>
  );
};


export default Header;
